<template>
  <div>
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      ref="html2Pdf"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <!-- <div class="page-wrapper">
          <div class="page-top">
            <div class="logo-icon">
              <img :src="this.exerciseData.exerciseLogo" />
            </div>
            <div class="top-row-par">
              <div class="top-row-1">
                <div class="name-area">
                  <p>{{ this.exerciseData.subject.name }}</p>
                </div>
                <div class="description-area">
                  <p>{{ this.exerciseData.instruction }}</p>
                </div>
              </div>
              <div class="icon-area">
                <div class="icon">
                  <img :src="this.exerciseData.icons[0]" />
                </div>
                <div class="icon">
                  <img :src="this.exerciseData.icons[1]" />
                </div>
                <div class="icon">
                  <img :src="this.exerciseData.icons[2]" />
                </div>
                <div class="icon">
                  <img :src="this.exerciseData.icons[3]" />
                </div>
                <div class="icon">
                  <img :src="this.exerciseData.icons[4]" />
                </div>
                <div class="icon">
                  <img :src="this.exerciseData.icons[5]" />
                </div>
                <div class="icon">
                  <img :src="this.exerciseData.icons[6]" />
                </div>
                <div class="icon">
                  <img :src="this.exerciseData.icons[7]" />
                </div>
                <div class="icon">
                  <img :src="this.exerciseData.icons[8]" />
                </div>
              </div>
            </div>
            <div class="qr-area">
              <div class="qr-label-text">ANLATIM</div>
              <img :src="this.exerciseData.instructionQR" />
            </div>
          </div>
          <div class="excercise-area">
            <img :src="this.exerciseData.question + '.jpg'" />
          </div>
          <div class="footer-area">
            <div class="excercise-id">
              <div class="excercise-fields">
                <div class="sn-label">Ö.No:</div>
                <div class="exc-input">
                  {{ this.exerciseData.subject.id }}
                </div>
              </div>
              <div class="excercise-fields">
                <div class="en-label">E.No:</div>
                <div class="exc-input">
                  {{ this.exerciseData.number }}
                </div>
              </div>
              <div class="excercise-fields-end">
                <div class="kn-label">K.No:</div>
                <div class="exc-input">{{ this.exerciseData.id }}</div>
              </div>
            </div>
            <div class="is-time">
              <div class="time-icon">İCON</div>
              <div class="is-time-text">Süre Hata</div>
            </div>
            <div class="time-input">
              <div class="time-text">SURE</div>
              <div class="time-input-area time-inputs">
                <div class="min-text">0-1DK</div>
                <div class="min-text">1-2DK</div>
                <div class="min-text">2-3DK</div>
              </div>
              <div class="time-input-area pre-letter">
                <div class="min-text">A</div>
                <div class="min-text">B</div>
                <div class="min-text">C</div>
              </div>
              <div class="time-input-area time-inputs">
                <div class="min-text">3-4DK</div>
                <div class="min-text">4-5DK</div>
                <div class="min-text">5DK+</div>
              </div>
              <div class="time-input-area pre-letter">
                <div class="min-text">D</div>
                <div class="min-text">E</div>
                <div class="min-text">F</div>
              </div>
            </div>
            <div class="answer-input">
              <div class="time-text">HATA</div>
              <div class="time-input-area time-inputs">
                <div class="min-text">0</div>
                <div class="min-text">1</div>
                <div class="min-text">2</div>
              </div>
              <div class="time-input-area pre-letter">
                <div class="min-text">A</div>
                <div class="min-text">B</div>
                <div class="min-text">C</div>
              </div>
              <div class="time-input-area time-inputs">
                <div class="min-text">3</div>
                <div class="min-text">4</div>
                <div class="min-text">5+</div>
              </div>
              <div class="time-input-area pre-letter">
                <div class="min-text">D</div>
                <div class="min-text">E</div>
                <div class="min-text">F</div>
              </div>
            </div>
            <div class="footer-qrcode">
              <div class="qr-label-text">
                <div class="qr-text">QR Code</div>
              </div>
              <div class="qr-area-zone">
                <img :src="this.exerciseData.qrLink" />
              </div>
            </div>
          </div>
        </div> -->
        PDF Content Here

        <div>
          <!-- <h1>{{ this.exerciseData.instruction }}</h1>
          <div class="excercise-area">
            <img :src="this.exerciseData.question" />
            <p>{{ this.exerciseData.question }}</p>
          </div> -->
        </div>
        sss
      </section>
      sss
    </vue3-html2pdf>
    <b-button
      variant="primary"
      @click="generateReport"
    />
  </div>
</template>
<script>
import Vue3Html2pdf from 'vue3-html2pdf'
import axios from '@axios'
import { BButton } from 'bootstrap-vue'

export default {

  components: {
    Vue3Html2pdf,
    BButton,
  },
  data() {
    return {
      exerciseData: '',
      basePath: '@/assets/images/exerciseAssets/',
    }
  },
  created() {
    /* axios.get(`http://localhost:3000/exercise/lean/${this.$route.params.id}`).then((response) => {
      for (let i = 0; i < response.data.icons.length; i++) {
        response.data.icons[i] = `${this.basePath}${response.data.icons[i]}.png`;
      }
      response.data.question = `${this.basePath}${response.data.question}.jpg`;
      this.exerciseData = response.data;
      console.log(response.data);
      console.log(this.exerciseData.question);
      // this.$refs.html2Pdf.generatePdf();
      console.log(this.$refs.html2Pdf);
    }); */
  },
  mounted() {
    console.log(this.$route.params.id)
    /* this.generateReport(); */
  },
  methods: {
    /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>
<style scoped>
body {
  width: 794px;
  height: 1122px;
  margin: 40px;
  /*padding: 46px;*/
}

.page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.page-top {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
}

.headline {
  padding: 0%;
  margin: 0%;
}

.description-area p {
  margin: 5px;
  padding: 0;
}

.name-area p {
  margin: 5px;
  padding: 0;
}

.icon {
  margin: 5px;
  /* 4px if with border*/
  padding: 0%;
  /*border: 1px solid black;*/
  border-radius: 10px;
  width: 50px;
  height: 50px;
}

.headline-row-1 {
  padding: 0%;
  margin: 0%;
  height: 50px;
}

.headline-row-2 {
  padding: 0%;
  margin: 0%;
  height: 50px;
}

.excercise-area {
  padding: 0%;
  /*border: 1px solid black;*/
  height: 692px;
  width: 692px;
}

.footer {
  padding: 0%;
  margin: 0%;
}

.footer-area {
  display: flex;
  justify-content: space-between;
  padding: 0%;
  margin: 0%;
  height: 115px;
  width: 692px;
}

.excercise-id {
  width: 148px;
  height: 114px;
  border: 2px solid black;
  border-radius: 10px;
}

.is-time {
  display: flex;
  flex-direction: column;
  width: 38px;
  height: 114px;
  border: 2px solid black;
  border-radius: 10px;
}

.time-input {
  display: flex;
  flex-direction: row;
  width: 181px;
  height: 114px;
  border: 2px solid black;
  border-radius: 10px;
}

.answer-input {
  display: flex;
  flex-direction: row;
  width: 181px;
  height: 114px;
  border: 2px solid black;
  border-radius: 10px;
}

.is-time-text {
  height: 66.66%;
  border-top: 1px solid black;
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-size: 7px;
  line-height: 0.5;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-icon {
  height: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.time-text {
  width: 13.11%;
  height: 100%;
  border-right: 1px solid black;
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-size: 12px;
  line-height: 0.5;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.min-text {
  width: 100%;
  height: 33.33%;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
}

:last-child {
  border-bottom: none;
}

.time-input-area {
  width: 26.87%;
}

.time-text {
  width: 11.59%;
}

.time-inputs {
  width: 26.87%;
}

.pre-letter {
  width: 16.65%;
}

.answer-input {
  width: 152px;
  height: 114px;
  border: 2px solid black;
  border-radius: 10px;
}

.footer-qrcode {
  width: 85px;
  height: 114px;
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.excercise-fields {
  width: 100%;
  height: 33.3%;
  border-bottom: 1px solid black;
  display: flex;
}

.excercise-fields-end {
  width: 100%;
  height: 33.3%;
  display: flex;
}

.sn-label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 33.3%;
  border-right: 1px solid black;
}

.en-label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 33.3%;
  border-right: 1px solid black;
}

.kn-label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 33.3%;
  border-right: 1px solid black;
}

.footer-row {
  padding: 0%;
  margin: 0%;
}

.footer-col {
  padding: 0%;
  margin: 0%;
}

.instruction-icon {
  width: 50px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 10px;
}

.name-area {
  margin: 4px;
  padding: 0%;
  width: 227px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #000;
}

.description-area {
  margin: 4px;
  padding: 0%;
  width: 283px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #000;
}

.logo-icon {
  height: 114px;
  /*114.67*/
  /*width: 74px;*/
  width: 9.286%;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 4px;
  padding: 0%;
}

.logo-icon img {
  width: 90%;
}

.qr-label-text {
  height: 15%;
  width: 100%;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.qr-area-zone {
  width: 100%;
  height: 85%;
}

.qr-area {
  border-radius: 10px;
  border: 1px solid #000;
  height: 114px;
  width: 10.714%;
  /*width: 85px;*/
}

.qr-area img {
  width: 95%;
  height: 75%;
  margin-top: 5%;
}

.footer-qrcode img {
  width: 95%;
  height: 85%;
  margin-top: 5%;
}

.qr-triangle {
  width: 0;
  height: 0;
  margin-left: 13px;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid black;
}

.qr-label {
  border-bottom: 1px solid black;
  /*width: 85px;*/
  height: 14px;
  font-size: 6pt;
}

.top-row-par {
  width: 65.258%;
  display: flex;
  flex-direction: column;
}

.top-row-1 {
  display: flex;
  flex-direction: row;
}

.icon-area {
  display: flex;
  flex-direction: row;
}

img {
  display: block;
  margin: auto;
  height: 100%;
  width: 100%;
}
</style>
